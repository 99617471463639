<template>
  <transition name="modal-animation" v-if="modalActive !== false">
    <div class="modal" >
      <transition name="modal-animation-inner" v-if="modalActive !== false">
        <div class="modal-inner">
          <div class="modal-content">
            <div class="mh-50">
              <h1>Recibos</h1>
              <p>Gerar e enviar recibos</p>
              <div class="row">
                <p class="col-12 text-center bg-info p-1" v-show="statusTxt">{{ statusTxt }}</p>
              </div>
              <div class="row border-bottom mb-2">
                <div class="col-12 col-md-2">
                  Processo
                </div>
                <div class="col-12 col-md-3">
                  Pessoa do Recibo
                </div>
                <div class="col-12 col-md-4">
                  Pessoa
                </div>
                <div class="col-12 col-md-2">
                  Valor total
                </div>
                <div class="col-12 col-md-1">
                  Ações
                </div>
              </div>
              <div class="row" v-if="!value.length">
                Sem processos selecionados
              </div>
              <div class="row border-bottom p-2" v-for="item in value" v-bind:key="item.idregistroFinanceiro">
                <div class="col-12 col-md-2 pd1">
                  {{ item.numeroProcesso }}
                </div>
                <div class="col-12 col-md-3 pd1">
                  <v-select
                    label="nome"
                    class="md-form-control"
                    :clearable="false"
                    :searchable="true"
                    :options="personTypeList"
                    v-model="item.pessoaPadrao"
                  >
                    <template slot="no-options">
                      {{ 'Nenhum resultado encontrado' }}
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-md-4 pd1">
                    <div v-if="item.pessoaPadrao.id == 1 && item.idpessoa" v-on:click="showPersonData(item.idpessoa)" >
                      {{item.cliente}} ({{(item.clienteCpfCnpj) ? item.clienteCpfCnpj : 'N/A' }}) <i class="pull-right fa fa-info-circle"></i>
                    </div>
                    <div v-else-if="item.pessoaPadrao.id == 2 && item.idNotify" v-on:click="showPersonData(item.idNotify)">
                      {{item.notify}} ({{(item.notifyCpfCnpj) ? item.notifyCpfCnpj : 'N/A' }}) <i class="pull-right fa fa-info-circle"></i>
                    </div>
                    <div v-else-if="item.pessoaPadrao.id == 3 && item.idImportador" v-on:click="showPersonData(item.idImportador)">
                      {{item.importador}} ({{(item.importadorCpfCnpj) ? item.importadorCpfCnpj : 'N/A' }}) <i class="pull-right fa fa-info-circle"></i>
                    </div>
                    <div v-else-if="item.pessoaPadrao.id == 4 && item.idExportador" v-on:click="showPersonData(item.idExportador)">
                      {{item.exportador}} ({{(item.exportadorCpfCnpj) ? item.exportadorCpfCnpj : 'N/A' }}) <i class="pull-right fa fa-info-circle"></i>
                    </div>
                    <div v-else-if="item.pessoaPadrao.id == 5 && item.idAgenteOrigem" v-on:click="showPersonData(item.idAgenteOrigem)">
                      {{item.agenteOrigem}} ({{(item.agenteOrigemCpfCnpj) ? item.agenteOrigemCpfCnpj : 'N/A' }}) <i class="pull-right fa fa-info-circle"></i>
                    </div>
                    <div v-else-if="item.pessoaPadrao.id == 6  && item.agenteDestino" v-on:click="showPersonData(item.idAgenteDestino)" >
                      {{item.agenteDestino}} ({{(item.agenteDestinoCpfCnpj) ? item.agenteDestinoCpfCnpj : 'N/A' }}) <i class="pull-right fa fa-info-circle"></i>
                    </div>
                    <div v-else-if="item.pessoaPadrao.id == 7 && item.idDespachanteAduaneiro" v-on:click="showPersonData(item.idDespachanteAduaneiro)">
                      {{item.despachanteAduaneiro}} ({{(item.despachanteAduaneiroCpfCnpj) ? item.despachanteAduaneiroCpfCnpj : 'N/A' }}) <i class="pull-right fa fa-info-circle"></i>
                    </div>
                    <!-- idOutro -->
                    <div v-else-if="item.pessoaPadrao.id == 8">
                      <!-- Outro -->
                      <pessoa-field
                        :grey-field="false"
                        name="item.idOutro"
                        :show-info="true"
                        label=""
                        v-model="item.idOutro"
                        rootClassName="col-12 col-lg-12 md-form-control mb-4"
                        v-validate="'required'"
                        :error="errors.first('item.idOutro')"
                      />
                    </div>
                    <div v-else>
                      N/A
                    </div>
                </div>
                <div class="col-12 col-md-2 pd1">
                  {{ $util.formatNumber(item.valorRecebimentoTotalBrl, 2, ',', '.') }}
                </div>
                <div class="col-12 col-md-1">
                  <div v-if="item.status_geracao === 1">
                    <loading :active.sync="item.status_geracao === 1"
                             :can-cancel="false"
                             :height="30"
                             :width="30"
                             :is-full-page="false"></loading>
                  </div>
                  <button v-else class="btn mx-1" title="Anexar ao headcargo e fazer download do PDF" v-on:click="generateFile(item)"><i class="fa fa-download"></i></button>
                </div>
                <div class="col-12" v-if="item.status_geracao === 2 || item.status_geracao === 4">
                  <div v-if="item.status_geracao === 2" class="alert-success">
                    <i class="font-12 ti ti-check p-1"></i> Recibo gerado com sucesso!
                  </div>
                  <div v-else-if="item.status_geracao === 4" class="alert-danger">
                    <i class="font-11 ti ti-close p-1"></i> Erro ao gerar recibo. Tente novamente. Se o problema persistir por favor avise o administrador do sistema.
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="pull-right mt-5">
                <button class="btn btn-primary btnModal mx-1" v-show="sendingbtn === true"><i class="fa fa-spinner" aria-hidden="true"></i></button>
                <button class="btn btn-primary btnModal mx-1" v-show="sendingbtn === false || value.length <= 0" v-on:click="SendReciboAll()">Anexar todos ao headcargo</button>
                <button class="btn btn-primary btnModal mx-1" v-on:click="closeModal()">Fechar</button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import InvoiceService from '@/services/InvoiceService'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import PersonTypeService from '../services/PersonTypeService'
import PersonService from '../services/PersonService'
import PersonInfoView from '../views/Invoice/Invoice/PersonInfoView'
import PessoaField from './Forms/PessoaField'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'ReceiptModal',
  props: {
    modalActive: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array
    },
    personData: {
      type: Object
    }
  },
  components: {
    vSelect,
    PessoaField,
    Loading
  },
  data () {
    return {
      personList: [],
      personTypeList: [],
      sendingbtn: false,
      isLoading: false,
      pessoa: null,
      statusTxt: ''
    }
  },
  methods: {
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    personFetchOptions (search, loading) {
      let _this = this
      loading(true)
      let filters = {
        'searchTerm': search
      }

      InvoiceService.getListTakersAvailable(filters).then(res => {
        _this.personList = res.data.data
      }).finally(() => {
        loading(false)
      })
    },
    showPersonData (idPerson) {
      let _this = this
      _this.isLoading = true

      PersonService.getPerson(idPerson).then(res => {
        _this.$modal.show(
          PersonInfoView,
          { isSuperAdmin: _this.isSuperAdmin,
            person: res.data.data },
          { draggable: false, width: '78%', height: 'auto', adaptive: true },
          { 'before-close': event => {} }
        )
        _this.isLoading = false
      })
    },
    personTypesFetch () {
      PersonTypeService.getPersonTypes({}, 1, 1000).then(res => {
        this.personTypeList = res.data.data
      })
    },
    closeModal () {
      this.statusTxt = ''
      this.$emit('closeModal')
    },
    SendReciboAll () {
      this.loadingValue = [ ...this.value ]

      this.loadingValue.forEach((obj, index) => {
        let value = this.loadingValue[index]
        let me = this
        value.status_geracao = 1
        me.isLoading = true
        me.statusTxt = ''

        InvoiceService.getReceiptFile(value.idregistroFinanceiro, value.pessoaPadrao.id, (value.idOutro) ? value.idOutro.id : null).then(res => {
          value.status_geracao = 2
          // ENVIO DE EMAIL
          // FIM ENVIO DE EMAIL
          me.statusTxt = ''
        }).catch(() => {
          value.status_geracao = 4
        }).finally(() => {
          me.isLoading = false
        })
      })

      this.$emit('input', [...this.loadingValue])
    },
    generateFile (invoice) {
      let me = this
      me.isLoading = true
      me.statusTxt = ''

      let fileName = 'RECIBO - ' + (invoice.numeroProcesso).replaceAll('/', '.') + '.pdf'

      invoice.status_geracao = 1

      InvoiceService.getReceiptFile(invoice.idregistroFinanceiro, invoice.pessoaPadrao.id, (invoice.idOutro) ? invoice.idOutro.id : null).then(res => {
        // Download file
        const url = global.window.URL.createObjectURL(new Blob([res.data]))
        const link = global.document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        global.document.body.appendChild(link)
        link.click()

        me.statusTxt = ''
        invoice.status_geracao = 2
      }).catch(() => {
        me.statusTxt = ''
        invoice.status_geracao = 4
      }).finally(() => {
        me.isLoading = false
      })
    }
  },
  mounted () {
    this.personTypesFetch()
  }
}
</script>

<style scoped>
.modal {display: block; background-color: rgb(0,0,0,0.5);}
.modal-inner {width: 70%; margin: auto; margin-top: 20vh;}
.btnRight {margin-top: -42px; float: right;}
.pd1 {padding: 0.65rem 1.1rem;}
.pb-6 {padding-bottom: 60px;}
.mh-50 {max-height: 50vh;overflow:auto;}
.bg-success {background-color: #52cf52 !important;}
</style>
